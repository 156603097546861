const state = () => ({
  company: {},
  color: '#7550e1'
})

const mutations = {
  setCompany (state, company) {
    state.company = company
  },
  setColor (state, color) {
    state.color = color
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCwTTboW4gsuzVrmVxImXGvg0dOkVaPXww",
  authDomain: "umb-amanata.firebaseapp.com",
  projectId: "umb-amanata",
  storageBucket: "umb-amanata.appspot.com",
  messagingSenderId: "316106460576",
  appId: "1:316106460576:web:65334ed60787dc5b6f68c5",
  measurementId: "G-0YLP1E3722"
}

if (!firebase.apps.length) { 
  firebase.initializeApp(config)
}

export const db = firebase.firestore()

export default (ctx, inject) => {
  inject('firebase', firebase)
}